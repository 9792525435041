import React, { Fragment } from 'react';
import PrintComponent from './Print';

const InvoiceContain = () => {
    return (
        <Fragment>
            <PrintComponent />
        </Fragment >
    );
};
export default InvoiceContain;